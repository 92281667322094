.landing {
	position: relative;
	background: url("./img/showcase.jpg") no-repeat;
	background-position: center;
	background-size: cover;
	height: 100vh;
}

.landing-inner {
	padding-top: 80px;
}

.dark-overlay {
	background-color: rgba(0, 0, 0, 0.6);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.carousel {
	width: 640px;
	height: 360px;
	margin: auto;
}

.carousel-inner {
	width: 640px;
	height: 360px;
}

.carousel-inner img {
	margin: auto;
}

.image-list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	grid-gap: 0 10px;
	grid-auto-rows: 10px;
}

.image-list img {
	width: 250px;
	grid-row-end: span 2;
}

.img-wrap:hover .img_description {
	visibility: visible;
	opacity: 1;
}

.img_wrap {
	position: relative;
}

.img_description {
	position: absolute;
	color: #fff;
	visibility: hidden;
}
